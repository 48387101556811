.App {
  color: white;
  text-align: center;
  transition: 0.5s;
  font-size: 15px; 
  margin-bottom: 40px;
  font-family: 'Rajdhani', sans-serif;
}

.result-item {
  font-family: 'Rajdhani', sans-serif;
  margin: 5px 0;
}

.searchinput {
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(83, 178, 140, 0.3); /* Zöld fény alapállapotban */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.searchinput:hover {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7); /* Fehér fény hover állapotban */
  transform: scale(1.05);
}

.searchinput:active {
  box-shadow: 0 0 15px rgba(83, 178, 140, 0.3); /* Zöld fény aktív állapotban */
  transform: scale(1.05);
}

.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #369497;
  border: none;
  color: white;
  text-align: center;
  font-size: 18px;
  padding: 12px;
  width: 120px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 10px;
}

.button-pos {
  margin: 0;
  position: absolute;
  bottom: 0%;
  right: 0%;
  transform: translate(-20%, -15%);
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

input[type=text] {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #62d5a3;
  border-radius: 6px;
  font-size: 16px;
  color: white;
  background-color: #000;
  padding: 12px 20px 12px 10px;
  box-shadow: 0 0 15px rgba(83, 178, 140, 0.3); /* Zöld fény alapállapotban */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

input[type=text]:hover {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7); /* Fehér fény hover állapotban */
  transform: scale(1.05);
}

input[type=text]:active {
  box-shadow: 0 0 15px rgba(83, 178, 140, 0.3); /* Zöld fény aktív állapotban */
  transform: scale(1.05);
}

.searchbar-pos {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 25%;
}

