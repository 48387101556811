.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 10px;
    justify-content: center;
}

.image-item {
    text-align: center;
}

.image-item img {
    width: 85px;
    height: 85px;
    border-radius: 15px; /* Lekerekített sarkok */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Finom árnyék */
    transition: transform 0.2s ease; /* Simább animáció */
}

.image-item img:hover {
    transform: scale(1.1); /* Növeli a képet, ha fölé viszed az egeret */
}

.image-item button {
    margin-top: 5px;
    font-size: 14px; /* Kicsit nagyobb betűméret */
    padding: 7px 14px; /* Nagyobb gombok */
    border-radius: 10px; /* Lekerekített gombok */
    background-color: #ffcccb; /* Aranyos pasztellszín */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Animáció a színváltozásra */
}

.image-item button:hover {
    background-color: #ff9999; /* Sötétebb árnyalat, ha fölé viszed az egeret */
}
